import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../Hooks/use-auth";

export default function Protected({ children }) {
	const { user } = useAuth();
	const location = useLocation();

	return (
		user ? children : <Navigate to="/login" state={{ from: location }} />
	)
}
