import React, {useState} from "react";
import { CirclePicker } from "react-color";
import { useCalendars } from "../Hooks/use-calendars";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../Layout/Breadcrumbs";

export function CreateCalendar() {
    const [name, setName] = useState('');
    const [calendarColor, setColor] = useState(undefined);
    const [readable, setReadable] = useState(true);
    const [writeable, setWriteable] = useState(true);
    const navigate = useNavigate();
    const {createCalendar} = useCalendars();

    const handleSubmit = e => {
        e.preventDefault();
        createCalendar(name, calendarColor, readable, writeable)
            .then(calendar => {
                navigate(`/calendars/${calendar.id}`,{replace: true});
            });
    }
    const crumbs = [
        { uri: "/", text: "Home" },
        { uri: "/calendars", text: "Calendars" }
    ];

    return (
        <article>
            <Breadcrumbs crumbs={crumbs} current="Create New Calendar" />
            <hr />
            <form onSubmit={handleSubmit}>
                <section className="mb-5">
                    <label className="font-bold" htmlFor="calendar_name">Calendar name</label>
                    <br />
                    <input className="border border-gray-600 rounded w-full shadow-inner px-4 py-2" type="text" id="calendar_name" onChange={({target}) => setName(target.value)} value={name} />
                </section>
                <section className="mb-5">
                    <p className="font-bold">Color Picker</p>
                    <aside className="flex justify-center">
                        <CirclePicker color={calendarColor} onChangeComplete={c => setColor(c.hex)} circleSize={36} circleSpacing={16} width={"90%"} />
                    </aside>
				    <p className="text-sm text-gray-600">Pick a color to use for this calendar in this app only.</p>
                </section>
                <section className="mb-5">
                    <input type="checkbox" className="mr-5" value="1" id="readable" checked={readable} onChange={({target}) => setReadable(target.checked)} />
                    <label className="font-bold" htmlFor="readable">Readable</label>
                </section>
                <section className="mb-5">
                    <input type="checkbox" className="mr-5" value="1" id="writeable" checked={writeable} onChange={({target}) => setWriteable(target.checked)} />
                    <label className="font-bold" htmlFor="writeable">Writeable</label>
                </section>
                <section className="mb-5">
                    <button className="bg-blue-500 text-white w-full py-3 rounded font-bold text-center" type="submit">Create</button>
                </section>
            </form>
        </article>
    )
}