import React, { useState, useContext, createContext, useEffect } from "react";
import * as Api from '../Api';
import brain from "../Brain";
import Protected from "../Util/Protected";

const calendarContext = createContext();

export function ProvideCalendars({children}){
    const calendars = useProvideCalendars();
    return (
        <Protected>
            <calendarContext.Provider value={calendars}>
                {children}
            </calendarContext.Provider>
        </Protected>
    );
}

export const useCalendars = () => {
    return useContext(calendarContext);
};

function useProvideCalendars(){
    const [loading, setLoading] = useState(false);
    const [calendars, setCalendars] = useState(brain.get('calendars'));

    const getCalendars = async () => {
        setLoading(true);
        const cals = await Api.get('calendars');
        setLoading(false);
        return cals;
    }

    const pull = async () => {
        if (loading) {
            return null;
        }
        const data = await getCalendars();
        const fullData = {data, last_pull: Math.floor(Date.now() / 1000)};
        setCalendars(fullData);
        return fullData
    };

    const getCalendar = id => {
        if (!calendars) {
            return undefined;
        }
        if (!calendars.data || !calendars.data.length) {
            return undefined;
        }
        return calendars.data.filter(i => i.id === id)[0] || undefined;
    };

    const createCalendar = async (name, color, readable, writeable) => {
        const data = await Api.post('/calendars', {name, color, readable, writeable});
        const calendarData = [...calendars.data, data];
        setCalendars({
            data: calendarData,
            last_pull: Math.floor(Date.now() / 1000)
        });
        return data;
    }

    useEffect(() => {
        const { last_pull } = brain.get('calendars') || { last_pull: 0 }
        if ( calendars && calendars.last_pull !== last_pull ) {
            brain.set('calendars', calendars);
        }
    });

    return {loading, calendars, pull, getCalendar, createCalendar};
}