import React, { useState, useContext, createContext } from "react"
import * as Api from '../Api'
import Protected from "../Util/Protected"

const eventsContext = createContext()

export function ProvideEvents({children}) {
    const events = useProvideEvents()
    return (
        <Protected>
            <eventsContext.Provider value={events}>
                {children}
            </eventsContext.Provider>
        </Protected>
    )
}

export const useEvents = () => useContext(eventsContext)

function useProvideEvents() {
    const [loading, setLoading] = useState(false)
    const [events, setEvents] = useState({})

    const fetch = async calendar => {
        setLoading(true)
        const calEvents = await Api.get(`calendars/${calendar}/events`)
        const newEvents = {}
        calEvents.forEach(event => {
            const key = `${calendar}.${event.id}`
            newEvents[key] = {...event, calendar}
        });
        setEvents({...events, ...newEvents})
        setLoading(false)
        return newEvents
    }

    const fetchAllEvents = async () => {
        setLoading(true)
        const allEvents = await Api.get('calendars/events')
        const newEvents = {};
        allEvents.forEach(event => {
            const key = `${event.calendar}.${event.id}`
            newEvents[key] = event
        })
        setEvents(newEvents)
        setLoading(false)
        return newEvents
    }

    const getEventsByCalendar = async calendar => {
        return await fetch(calendar)
    }

    const getAllEvents = async () => {
        return await fetchAllEvents()
    }

    return {loading, events, getEventsByCalendar, getAllEvents}
}