import './index.css';
import Main from './Pages/Main';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { ProvideAuth } from './Hooks/use-auth';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Protected from './Util/Protected';
import { CreateCalendar } from './Pages/CreateCalendar';
import Calendar from './Pages/Calendar';
import Calendars from './Pages/Calendars';
import Events from './Pages/Events';
import { ProvideCalendars as PC } from './Hooks/use-calendars';
import { ProvideEvents as PE } from './Hooks/use-events'

function App() {
	return (
		<ProvideAuth>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Main />}>
						<Route index element={<Protected><Home /></Protected>} />
						<Route path='login' element={<Login />} />
						<Route path='calendars' element={<PC><Calendars /></PC>} />
						<Route path='calendars/:calendarId' element={<PC><PE><Calendar /></PE></PC>} />
						<Route path='calendars/add-new' element={<PC><CreateCalendar /></PC>} />
						<Route path='events' element={<PC><PE><Events /></PE></PC>} />
					</Route>
				</Routes>
			</BrowserRouter>
		</ProvideAuth>
	)
}

export default App;
