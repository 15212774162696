import React from "react";
import { Link } from "react-router-dom";

export function Breadcrumbs(props) {
    if (! (props.crumbs && props.crumbs.length)) {
        return <span />;
    }
    let k = 0;

    return (
        <nav className="mb-3">
            {props.crumbs.map(crumb => (
                <span key={k++} className="pr-2">
                    <Link to={crumb.uri} className="text-gray-400 hover:text-gray-700 underline pr-2">
                        {crumb.text}
                    </Link>
                    »
                </span>
            ))}
            <span>{props.current}</span>
        </nav>
    )
}