import { CalendarDaysIcon, TicketIcon } from "@heroicons/react/24/solid";
import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
	return (
		<article>
			<nav className="grid grid-cols-2">
				<Link to="calendars" title="Calendars">
					<CalendarDaysIcon />
					<h2 className="text-center font-bold text-2xl -mt-3">Calendars</h2>
				</Link>
				<Link to="events" title="All Events">
					<TicketIcon />
					<h2 className="text-center font-bold text-2xl -mt-3">All Events</h2>
				</Link>
			</nav>
		</article>
	)
}
