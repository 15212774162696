import React from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";

export function Layout({ children }) {
	return (
		<div className="flex flex-col min-h-screen">
			<Header />
			<section className="mx-5 grow">
				{ children }
			</section>
			<Footer />
		</div>
	)
}
