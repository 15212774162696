import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCalendars } from "../Hooks/use-calendars";
import { Breadcrumbs } from "../Layout/Breadcrumbs";
import { getTextColor } from "../Util/helpers";
import { useEvents } from "../Hooks/use-events";
import { StartEndDates } from "../Layout/StartEndDates";
import { DateBox } from "../Layout/DateBox";
import { ArrowPathIcon, PlusIcon } from "@heroicons/react/24/solid";

export default function Calendar() {
    const params = useParams();
    const {getCalendar} = useCalendars();
    const calendar = getCalendar(params.calendarId) || {};
    const {getEventsByCalendar} = useEvents()
    const [calEvents, setCalEvents] = useState({})
    const [buttonState, setButtonState] = useState(false)
    useEffect(() => {
        let mounted = true;
        if (mounted) getEventsByCalendar(params.calendarId).then(setCalEvents)
        return () => {mounted = false}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const crumbs = [
        { uri: "/", text: "Home" },
        { uri: "/calendars", text: "Calendars" }
    ];
    return (
        <section>
            <Breadcrumbs crumbs={crumbs} current={`Calendar: ${calendar.name}`} />
            <hr/>
            <article>
                <h2 className={`text-${getTextColor(calendar.color)} -mx-5 p-2 px-5 my-2 font-bold`} style={{backgroundColor: calendar.color}}>{calendar.name || ''}</h2>
                <nav className="flex justify-end">
                    <button className="w-12 h-12 bg-blue-600 rounded-full mr-4">
                        <ArrowPathIcon className="w-8 h-8 m-auto text-white" />
                    </button>
                    <button className={`w-12 h-12 bg-green-700 rounded-full transition-all${buttonState ? ' rotate-45' : ''}`} onClick={() => setButtonState(!buttonState)}>
                        <PlusIcon className="w-8 h-8 m-auto text-white" />
                    </button>
                </nav>
                {
                    Object.entries(calEvents).map(([key, item]) => <>
                        <aside key={key} className="mb-8 flex">
                            <DateBox dateObject={item.start} />
                            <section className="ml-5">
                            <h3 className="font-bold text-lg">{item.name}</h3>
                            <StartEndDates event={item} />
                            </section>
                        </aside>
                    </>)
                }
            </article>
        </section>
    );
}