import axios from 'axios';
import config from './config';

const client = (function () {
	let clientObject;
	return function () {
		if (!clientObject) {
			const instanceConfig = {
				baseURL: `${config.host}/api`,
				headers: {common:{}},
			}
			clientObject = axios.create(instanceConfig)
		}
		return clientObject;
	};
})();

export function setAuthorizationHeader(key) {
	client().defaults.headers.common.Authorization = key ? `Bearer ${key}` : undefined;
}

export function request(...all) {
	return client().request(...all);
}

export async function get(resource, params) {
	const response = await client().get(resource, { params });
	return response.data;
}

export async function post(resource, data) {
	const response = await client().post(resource, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.data;
}
