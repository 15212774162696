import React, { useState, useEffect, useContext, createContext } from "react";
import { setAuthorizationHeader, request, post } from "../Api";
import brain from "../Brain";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	const [initialized, setInitialized] = useState(false);
	const [user, setUser] = useState(brain.get('current_user'));

	const signin = async (email, pass, device_name) => {
		const data = await post('tokens', { email, pass, device_name });
		setUser(data);
		setAuthorizationHeader(data.token);
		return data;
	};

	const signout = () => {
		return request({ url: 'session', 'method': 'delete' })
			.then(
				() => setUser(null),
				() => setUser(null)
			);
	};

	useEffect(() => {
		const current_user = brain.get('current_user');
		if (current_user && !user) {
			brain.delete('current_user');
			sessionStorage.clear();
		} else if (user && !current_user) {
			brain.set('current_user', user);
		}
		if(!initialized) {
			if (current_user) {
				setAuthorizationHeader(current_user.token);
			}
			setInitialized(true);
		}
	}, [user, initialized]);

	return {
		user,
		signin,
		signout
	}
}
