import React, {useEffect, useState} from "react";
import { useEvents } from "../Hooks/use-events";
import { Breadcrumbs } from "../Layout/Breadcrumbs";

function getLoadingContent() {
    return <p>Loading...</p>
}

function getLoadedContent(events) {
    return <p>There are {events.length} events.</p>
}

export default function Events() {
    const {loading, getAllEvents} = useEvents()
    const [allEvents, setAllEvents] = useState([])

    useEffect(() => {
        let mounted = true;
        if (mounted) getAllEvents().then(setAllEvents)
        return () => {mounted = false}
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <section>
        <Breadcrumbs crumbs={[{uri: '/', text: 'Home'}]} current="All Events" />
        <p>Events</p>
        {loading ? getLoadingContent() : getLoadedContent(allEvents)}
    </section>
}