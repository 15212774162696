import React, {useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/use-auth";

export default function Login() {
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();
	const [email, setEmail] = useState('');
	const [pass, setPass] = useState('');
	const [name, setName] = useState('');

	const { from } = location.state || { from: { pathname: '/' } };

	const submitHandler = (e) => {
		e.preventDefault();
		auth.signin(email, pass, name)
			.then(() => {navigate(from.pathname || '/', {replace: true})});
	};

	return (
		<form onSubmit={submitHandler}>
			<div className="mb-5">
				<label className="font-bold" htmlFor="email">Email Address</label>
				<br/>
				<input type="email"
				       id="email"
					   onChange={({target}) => setEmail(target.value)}
					   value={email}
					   className="border border-gray-600 rounded w-full shadow-inner px-4 py-2"
				/>
			</div>
			<div className="mb-5">
				<label className="font-bold" htmlFor="pass">Password</label>
				<br/>
				<input type="password"
				       id="pass"
					   onChange={({ target }) => setPass(target.value)}
					   value={pass}
					   className="border border-gray-600 rounded w-full shadow-inner px-4 py-2"
				/>
			</div>
			<div className="mb-5">
				<label className="font-bold" htmlFor="name">Device Name</label>
				<br />
				<input type="text"
				       id="name"
					   onChange={({ target }) => setName(target.value)}
					   value={name}
					   className="border border-gray-600 rounded w-full shadow-inner px-4 py-2"
				/>
				<p className="text-sm text-gray-600">Set a memorable device name for this device</p>
			</div>
			<button type="submit" className="bg-blue-500 text-white w-full py-3 rounded font-bold text-center">Log In</button>
		</form>
	)
}
