import React from "react";
import { Link } from "react-router-dom";
import { CalendarIcon } from "@heroicons/react/24/solid"

export function Header() {
    return (
        <header className="text-center py-3 px-5 mb-3 bg-blue-500">
            <h1>
                <Link to="/" className="flex justify-between items-center text-white">
                    <CalendarIcon className="inline w-16" />
                    <span className="font-extrabold text-3xl uppercase">JPB Calendars</span>
                </Link>
            </h1>
        </header>
    );
}