class Brain {

	get(thing) {
		const value = localStorage.getItem(thing);
		return value ? JSON.parse(value) : undefined;
	}

	set(thing, value) {
		if (value === undefined) {
			this.delete(thing);
			return;
		}
		localStorage.setItem(thing, JSON.stringify(value));
	}

	delete(thing) {
		localStorage.removeItem(thing);
	}

}

const brain = new Brain();
export default brain;
