import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Hooks/use-auth";

export function Footer() {
	const { user, signout } = useAuth()

    const onClickHandler = e => {
        e.preventDefault();
        signout();
    };

    return (
        <footer className="py-3 px-5 mt-3 bg-blue-500 text-white flex justify-between items-center">
            <span>{user ? `Logged in as ${user.name}` : 'You are not logged in'}</span>
            {user
                ? <button onClick={onClickHandler}>Log out</button>
                : <Link to="/login">Log in</Link>}
        </footer>
    );
}