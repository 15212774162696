import React from "react";

function formatTime(dt) {
    const actual = new Date(dt)
    return actual.toLocaleTimeString()
}

export function StartEndDates({event}) {
    const {start, end, all_day} = event
    const now = new Date()
    const realStart = Date.parse(start || now)
    const realEnd = Date.parse(end || now)
    return <p>
        <small>
            {
                all_day
                    ? 'All Day'
                    : `${formatTime(realStart)} — ${formatTime(realEnd)}`
            }
        </small>
    </p>
}