import React from "react";
import { Link } from "react-router-dom";
import { useCalendars } from "../Hooks/use-calendars";
import { Breadcrumbs } from "../Layout/Breadcrumbs";
import { getTextColor } from "../Util/helpers";
import { ArrowPathIcon, PlusIcon } from "@heroicons/react/24/solid";

export default function Calendars() {
    const {calendars, loading, pull} = useCalendars();

    const getContent = () => {
        if (!calendars) {
            if (loading) {
                return <p>Loading...</p>
            }
            return <p>Something is wrong...</p>
        }
        if (!calendars.data) {
            return <p>Something is quite odd...</p>
        }
        if ( !calendars.data.length) {
            return <p>You do not have any calendars.</p>
        }
        return (
            <div>
                <ul>
                    {calendars.data.map(calendar => (
                        <li key={calendar.id} className={`p-2 text-${getTextColor(calendar.color)} my-2 rounded`} style={{backgroundColor: calendar.color}}>
                            <Link className="font-bold block" to={`/calendars/${calendar.id}`}>{calendar.name}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const reloadClickHandler = e => {
        e.preventDefault()
        pull()
    }

    const reloadSpin = loading ? ' animate-spin' : '';

	return (
        <div>
            <Breadcrumbs crumbs={[{uri:"/", text:"Home"}]} current="Calendars" />
            <h2 className="font-bold text-3xl mb-5">Calendars</h2>
            {getContent()}
            <footer className="flex justify-end mt-8">
                <button className={`rounded-full bg-blue-600 w-12 h-12 mr-4${reloadSpin}`} onClick={reloadClickHandler}>
                    <ArrowPathIcon className="text-white w-8 h-8 m-auto" />
                </button>
                <Link to="/calendars/add-new" title="Create New Calendar" className="w-12 h-12 bg-green-700 rounded-full text-center align-middle flex">
                    <PlusIcon className="w-8 h-8 m-auto text-white" />
                </Link>
            </footer>
        </div>
	)
}
